.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;
  box-sizing: border-box;
}

.form {
  display: grid;
  grid-gap: 20px;
  margin-top: 40px;
  width: 100%;
}

.title {
  font-size: 24px;
}

.input {
  height: 50px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  outline: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #000;

  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  text-transform: uppercase;
  color: #fff;
  background: #000;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.7);
  }

  &:disabled {
    background-color: rgba(0, 0, 0, 0.3);
    cursor: default;
  }
}
